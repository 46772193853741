@keyframes visible {
  0% {
    opacity: 0;
    height: auto;
  }

  100% {
    opacity: 1;
  }
}
.mega-container {
  width: 200px !important;
}
.mega-container:hover .mega {
  animation-name: visible;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  visibility: visible;
  opacity: 1;
}

[dir="rtl"] .mega-container:hover .mega {
  right: auto;
  left: 0;
}

@media (max-width: 640px) {
  .mega-container {
    width: unset !important;
  }
}
