.image {
  border: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: #f8f9fd;
  position: relative;

  & .fall-back {
    width: 100%;
    height: 100%;
    padding: 5px;
    opacity: 0.3;
  }
}
