/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.simple-loader {
  //   border: 4px solid rgba(21, 39, 5);
  border: 4px solid #f2f2f2;
  border-radius: 50%;
  border-top: 4px solid #453182;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;

  &__small {
    border-width: 2px;
    border-top-width: 2px;
    width: 30px;
    height: 30px;
  }
}
