.statuses {
  text-transform: capitalize;

  &__pending,
  &__assigned {
    background-color: orange;
    color: white;
  }

  &__in-progress {
    background-color: rgb(85, 77, 205);
    color: white;
  }

  &__finished,
  &__successful {
    // background-color: green;
    background-color: rgb(23, 189, 23);
    color: white;
  }

  &__due {
    background-color: #f6e34c;
    color: white;
  }

  &__failed,
  &__no-show {
    background-color: red;
    color: white;
  }
}

.ant-rate-star-first,
.ant-rate-star-second {
  color: lightgray;
}

.ant-rate {
  color: gold;
}

.text-scaled {
  transform: scaleY(0.9);
}

// search places input styles (Google maps)

.pac-container {
  z-index: 99999;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  word-break: break-word;

  &__1 {
    -webkit-line-clamp: 1;
    // max-height: 23px;
  }
  &__2 {
    -webkit-line-clamp: 2;
    max-height: 47px;
  }
}

.fadded {
  z-index: -1;
  opacity: 0.5;
  pointer-events: none;
}

.attendance-days {
  & .ant-tooltip-inner {
    padding: 20px;
    border-radius: 12px;
    background-color: #1f2126;
  }
}

.open-side-bar {
  left: 0 !important;
}

[dir="rtl"] .open-side-bar {
  right: 0 !important;
}

.map-marker-label {
  position: absolute;
  bottom: 0;
  left: -60px;
}

.survey-builder {
  &__modal {
    width: 800px;
    max-width: 800px !important;
    padding: 50px !important;

    * .ant-select-multiple .ant-select-selection-placeholder {
      left: 0;
    }

    & .ant-switch-checked,
    .ant-switch {
      background-color: #f0edfa !important;
    }

    & .ant-switch-checked {
      & .ant-switch-handle {
        &::before {
          background-color: #453182;
        }
      }
    }
  }
}

.ant-picker.ant-picker-range {
  border-radius: 12px;
}
