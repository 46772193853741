.flash-message {
  height: 0;
  width: 100%;
  background-color: #f89c1c;
  opacity: 0;

  font-size: 1.125rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  position: sticky;
  top: 0;
  z-index: 9999;

  &__open {
    opacity: 1;
    min-height: 50px;
  }

  &__close-icon {
    position: absolute;
    right: 10px;
    left: unset;
    cursor: pointer;
  }
}

[dir="rtl"] {
  & .flash-message__close-icon {
    right: unset;
    left: 10px;
  }
}
